import {getNotificationPermission} from './helpers/getNotificationPermission';
import {isStorageSupported} from './helpers/isStorageSupported';
import {addJitsuProperty} from './services/analytics/jitsu-helpers';
import {publishEvent} from './services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';

if (window.Site.oneSignalAppId) {
  window.OneSignal = window.OneSignal || [];

  window.OneSignal.push(() => {
    window.OneSignal.init({
      appId: window.Site.oneSignalAppId
    });
  });

  window.OneSignal.push(() => {
    window.OneSignal.sendTags({
      platform: window.Site.targeting.platform
    });
  });

  window.OneSignal.push(() => {
    window.OneSignal.on('subscriptionChange', isSubscribed => {
      getNotificationPermission()
        .then(permission => {
          addJitsuProperty('permission_notification', permission);
        });
      window.OneSignal.getUserId(playerId => {
        if (isSubscribed) {
          if (isStorageSupported('localStorage')) {
            localStorage.setItem('oneSignalPlayerId', playerId);
          }
          addJitsuProperty('player_id', playerId);
        }
        publishEvent(ANALYTIC_EVENTS.SUBSCRIPTION_CHANGE, {
          isSubscribed
        });
      });
    });
  });
}

if ('serviceWorker' in window.navigator) {
  window.navigator.serviceWorker.register('/one-signal-service-worker.js');
}
